import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import settings from "../settings"
import { Row } from "react-bootstrap"

import "./collections.scss"

const CollectionPage = ({ data}) => {
let title ="";

if(data.allMysqlItineraries.nodes.length>0){
  title = data.allMysqlItineraries.nodes[0].popular_collection;
}
  return (
    <Layout>
      <Seo title="Collection" />
      <Destinations data={data.allMysqlItineraries.nodes} title={title} />
    </Layout>)
}
const Card = ({backgroundImage, title, subtitle, url})=>{
  return (<div className="card" onClick={(e)=>{window.location.href=url}}>
    <div className="image" style={{backgroundImage: `url('${backgroundImage}')`}}>
      <div className="overlay">
        <p className="subtitle">{subtitle}</p>
      </div>
    </div>
    <p>{title}</p>

  </div>)

}

const Destinations=({data, title})=>{
  return (
    <div className="featuredDestination popular">
      <div className="section">
        <h2>Explore {title}</h2>
        <Row className="card-container">
          {
            data.map((iti)=>{
              const content = JSON.parse(iti.content);
              const thumbUrl = JSON.parse(content.primaryInfo.bannerImage).thumbUrl;
              if(iti.cities!==null){
                return (<Card backgroundImage={settings.IMAGE_URL+thumbUrl+".webp"}
                              title={iti.title}
                              subtitle={iti.cities?.title}
                              url={`/${iti.slug.toLowerCase()}`}/>)
              }
              return <div></div>
            })
          }
        </Row>
      </div>
    </div>
  )
}



export default CollectionPage

//highlight-start
export const query = graphql`
 query CollectionQuery($uri: String!) {
  allMysqlItineraries(filter: {popular_collection: {eq: $uri}}) {
    nodes {
      title
      slug
      content
      popular_collection
    }
  }
}
`
//highlight-end
